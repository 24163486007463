import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthResp } from "app/models/auth-resp";
import { ToastService } from "./toast.service";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TvaritDevtypeService {
  constructor(private http: HttpClient, private toastService: ToastService) {}

  submitRepairData(reqObj) {
    return this.http.post("/api/account/tvarit/maintenanceLog", reqObj).pipe(
      map(
        (res: AuthResp) => {
          if (res.success === true) {
            this.toastService.showToast(
              "Repair Form Submitted Successfully",
              "success"
            );
          } else {
            this.toastService.showToast(res.errors[0], "danger");
          }
          return res.success;
        },
        (err) => {
          this.toastService.showToast(
            "Something went wrong. Try Again!",
            "danger"
          );
          return false;
        }
      )
    );
  }

  updateRepairData(reqObj) {
    return this.http.post("/api/account/tvarit/maintenanceLog", reqObj).pipe(
      map(
        (res: AuthResp) => {
          if (res.success === true) {
            this.toastService.showToast(
              "Repair Form Submitted Successfully",
              "success"
            );
          } else {
            this.toastService.showToast(res.errors[0], "danger");
          }
          return res.success;
        },
        (err) => {
          this.toastService.showToast(
            "Something went wrong. Try Again!",
            "danger"
          );
          return false;
        }
      )
    );
  }

  getMaintenanceLogs(
    pageNumber: number,
    dataCount: number,
    devID: string,
    searchObj: any
  ) {
    return this.http
      .put(`/api/account/tvarit/maintenanceLog/${pageNumber}/${dataCount}`, {
        devID,
        searchObj,
      })
      .pipe(
        map(
          (res: AuthResp) => {
            if (res.success === true) {
              return res.data;
            } else {
              this.toastService.showToast(res.errors[0], "danger");
              return false;
            }
          },
          (err) => {
            this.toastService.showToast(
              "Something went wrong. Try Again!",
              "danger"
            );
            return false;
          }
        )
      );
  }
}
