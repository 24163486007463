import { ToastService } from './toast.service';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthResp } from 'app/models/auth-resp';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IdexDevtype{

  constructor(
    private http: HttpClient,
    private toastService:ToastService
  ) { }


  getStatsData(reqObj) {
    return this.http.put(`/api/account/idex/getStatsData`, reqObj).pipe(
      tap(console.log),
      map(
        (res: AuthResp) => {
          if (res.success === true) {
            return res.data;
          } else {
            return false;
          }
        },
        err => {
          this.toastService.showToast(
            "Something went wrong. Try Again!",
            "danger"
          );
          return false;
        }
      )
    );
  }

  getLifeSectionsData(reqObj) {
    return this.http.put(`/api/account/idex/getLifeSectionData`, reqObj).pipe(
      tap(console.log),
      map(
        (res: AuthResp) => {
          if (res.success === true) {
            return res.data;
          } else {
            return false;
          }
        },
        err => {
          this.toastService.showToast(
            "Something went wrong. Try Again!",
            "danger"
          );
          return false;
        }
      )
    );
  }

  getProductionData(type,reqObj) {
    return this.http.put(`/api/account/idex/consumption/${type}`, reqObj).pipe(
      tap(console.log),
      map(
        (res: AuthResp) => {
          if (res.success === true) {
            return res.data;
          } else {
            return false;
          }
        },
        err => {
          this.toastService.showToast(
            "Something went wrong. Try Again!",
            "danger"
          );
          return false;
        }
      )
    );
  }

  getFlowrateData(reqObj) {
    return this.http.put(`/api/account/idex/getFlowrate`, reqObj).pipe(
      tap(console.log),
      map(
        (res: AuthResp) => {
          if (res.success === true) {
            return res.data;
          } else {
            return false;
          }
        },
        err => {
          this.toastService.showToast(
            "Something went wrong. Try Again!",
            "danger"
          );
          return false;
        }
      )
    );
  }
}
