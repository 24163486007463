import { map, tap, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthResp } from "../models/auth-resp";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastService } from "./toast.service";
import { of } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class QuotaService {
  constructor(private http: HttpClient, private toastService: ToastService) {}

  getUserQuota() {
    return this.http.get("/api/account/profile/quota/").pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], "danger");
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast(
          "Something went wrong, Try Again!!",
          "danger"
        );
        return of(false);
      })
    );
  }
  getQuotaActionData(pageNumber, dataCount, type) {
    return this.http.get(`/api/account/profile/quotaRecord/${type}/${pageNumber}/${dataCount}`).pipe(
      map((res: AuthResp) => {
        if (res.success) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast(
          "Something went wrong, Try Again!!",
          "danger"
        );
        return of(false);
      })
    );
  }
}
