import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { Geofence } from 'app/models/geofence';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { AuthResp } from 'app/models/auth-resp';
import { ToastService } from './toast.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class GeofencesService {

  private subject = new BehaviorSubject(null);
  geofences$: Observable<Geofence[]> = this.subject.asObservable();

  constructor(
    private http: HttpClient,
    private toastService: ToastService) { }

  getGeofences(geofence) {
    return this.http.get('/api/account/geofence/' + geofence).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          geofence == 'all' ? this.subject.next(res.data) : '';
        } else {
          this.subject.next([]);
          this.toastService.showToast(res.errors[0], "danger");
        }
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        console.log("Get Geofences Err", err.message);
        this.subject.next([]);
        return throwError(err.message);
      })
    );
  }

  addGeofence(geofence) {
    return this.http.post("/api/account/geofence", geofence).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          this.toastService.showToast("Geofence added successfully!", "success");
          const geofences = this.subject.value;
          geofences.push(res.data);
          this.subject.next(geofences);
        } else {
          this.toastService.showToast(res.errors[0], "danger");
        }
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        console.log("Add geofence err", err.message);
        return throwError(err.message);
      })
    );
  }

  deleteGeofence(geofence_id) {
    const geofences = this.subject.value;
    const indexToDelete = _.findIndex(geofences, { geofenceID: geofence_id });
    const geofenceToDelete = _.remove(geofences, { geofenceID: geofence_id });
    // geofences.splice(indexToDelete, 1);
    console.log('to delete', geofenceToDelete, indexToDelete, geofences);
    this.subject.next(_.cloneDeep(geofences));

    return this.http.delete("/api/account/geofence/" + geofence_id).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          this.toastService.showToast("Geofence deleted successfully", "success");
        } else {
          this.toastService.showToast(res.errors[0], "danger");
          geofences.splice(indexToDelete, 0, geofenceToDelete);
          this.subject.next(_.cloneDeep(geofences));
        }
        return res.success;
      }),
      catchError((err: HttpErrorResponse) => {
        console.log("Delete geofence err", err.message);
        this.toastService.showToast(
          "Something went wrong. Try again!",
          "danger"
        );
        geofences.splice(indexToDelete, 0, geofenceToDelete);
        this.subject.next(_.cloneDeep(geofences));
        return throwError(err.message);
      })
    );
  }
}
