import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResp } from 'app/models/auth-resp';
import { catchError, map, tap } from 'rxjs/operators';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class EscortsDashService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private spinner: NgxSpinnerService
  ) { }

  getOverallConsumption(type) {
    return this.http.put('/api/account/escort/overallConsumption', { type }).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }

  getConsumptionVsUnits(reqObj) {
    return this.http.put('/api/account/escort/consumptionVsUnits', reqObj).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          console.log('response vs unit', res.data);

          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }

  getAirPressure(reqObj) {
    return this.http.put('/api/account/escort/airPressure', reqObj).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }


  getDieselConsumption(type) {
    return this.http.put('/api/account/escort/dieselConsumption', { type }).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }

  getConsumptionVsUnits2(reqObj) {
    console.log('req obj cvu2', reqObj);
    return this.http.put('/api/account/escort/consumptionVsUnits2', reqObj).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }

  overallConsumption2(reqObj) {
    console.log('req obj cvu2', reqObj);

    return this.http.put('/api/account/escort/overallConsumption2', reqObj).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }

  getWaterDetails(reqObj) {
    console.log('req obj water', reqObj);

    return this.http.put('/api/account/escort/waterDetails', reqObj).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }

  downloadProductionSheet(obj) {
    console.log('req obj excel download', obj);
    this.spinner.show();
    return this.http.put('/api/account/escort/excel/download', obj).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        this.spinner.hide();
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }

  uploadProductionSheet(obj) {
    this.spinner.show();
    console.log('req obj excel upload', obj);
    return this.http.put('/api/account/escort/excel/upload', obj).pipe(
      tap(console.log),
      map((res: AuthResp) => {
        this.spinner.hide();
        if (res.success) {
          return res.success;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.spinner.hide();
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return of(err.message);
      }))
  }

}
