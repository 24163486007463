import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApcR1Service {
  stationConfigs = [];
  exportDevices = [];
  energyGenDevices = [];
  centralDevices = [];
  unitDevices = [];
  energyUnit;
  apcUnit;
  exportUnit;
  formValue:any;
  constructor() { }
  stationConfig(config: any) {
    this.stationConfigs.push(config);
  }
  exportDevice(device:any) {
    this.exportDevices.push(device)
  }
  energyGenDevice(device:any){
    this.energyGenDevices.push(device)
  }
  centralDevice(device:any){
    this.centralDevices.push(device)
  }
  unitDevice(device:any){
    this.unitDevices.push(device)
  }
}
