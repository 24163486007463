import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResp } from 'app/models/auth-resp';
import { Observable } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class EnergyAnalyticsService {

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
  ) { }

  getTableConfig() {
    return this.http.get('/api/account/energyAnalytics/').pipe(
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return Observable.throw(err.message);
      }))
  }

  addTableConfig(obj) {
    return this.http.post('/api/account/energyAnalytics/config/', obj).pipe(
      map((res: AuthResp) => {
        if (res.success) {
          this.toastService.showToast("Table Added Successfully", "success");
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return Observable.throw(err.message);
      }))
  }

  updateTableConfig(id, obj) {
    return this.http.put('/api/account/energyAnalytics/config/' + id, obj).pipe(
      map((res: AuthResp) => {
        if (res.success) {
          this.toastService.showToast("Table Updated Successfully", "success");
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return Observable.throw(err.message);
      }))
  }

  /**
   * Update Table Config and GET Total Data
   * @param obj - 
   * @returns 
   */
  getTableData(id, obj) {
    return this.http.put('/api/account/energyAnalytics/tableData/' + id, obj).pipe(
      map((res: AuthResp) => {
        if (res.success) {
          return res;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return Observable.throw(err.message);
      }))
  }

  getGraphData(id, obj) {
    return this.http.put('/api/account/energyAnalytics/graphData/', obj).pipe(
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return Observable.throw(err.message);
      }))
  }

  getChildTableConfig(obj: any, configType: string) {
    return this.http.put('/api/account/energyAnalytics/config/', { parent: obj, configType }).pipe(
      map((res: AuthResp) => {
        if (res.success) {
          return res.data;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return null;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return Observable.throw(err.message);
      }))
  }

  deleteTable(id: string, rootId) {
    return this.http.delete(`/api/account/energyAnalytics/config/${rootId}/${id}`).pipe(
      map((res: AuthResp) => {
        if (res.success) {
          this.toastService.showToast("Table deleted successfully", 'success');
          return true;
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.toastService.showToast('Something went wrong. Try again!', 'danger');
        return Observable.throw(err.message);
      }))
  }
  //get all table configs
  getAllConfigs(reqObj){
    return this.http.put(`/api/account/energyAnalytics/allConfigs`, reqObj).pipe(
      map((res: AuthResp) => {
        if(res.success) {
          return res.data
        } else {
          this.toastService.showToast(res.errors[0], 'danger');
        }
      })
    )
  }
  getSubConfigs(reqObj){
    return this.http.put('/api/account/energyAnalytics/subConfigs', reqObj).pipe(
      map((res:AuthResp) => {
        if(res.success){
          return res.data;
        }
        else{
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      })
    )
  }
  changeTitle(reqObj){
    return this.http.put('/api/account/energyAnalytics/titleChange', reqObj).pipe(
      map((res:AuthResp) => {
        if(res.success){
          return res.data;
        }
        else{
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      })
    )
  }
  removeRowTemporary(reqObj){
    return this.http.put('/api/account/energyAnalytics/temporaryDeleteRows', reqObj).pipe(
      map((res:AuthResp) => {
        if(res.success){
          return res.data;
        }
        else{
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      })
    )
  }
  restoreRows(reqObj){
    return this.http.put('/api/account/energyAnalytics/restoreRows', reqObj).pipe(
      map((res:AuthResp) => {
        if(res.success){
          return res.data;
        }
        else{
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      })
    )
  }

  refreshCache(reqObj:any){
    return this.http.put('/api/account/energyAnalytics/refreshCache', reqObj).pipe(
      map((res:AuthResp)=>{
        if(res.success){
          return true;
        }
        else{
          this.toastService.showToast(res.errors[0], 'danger');
          return false;
        }
      })
    )
  }
}
